import React from 'react'
import {Card, CardBody, CardHeader} from "reactstrap"
import {Link, router, usePage} from "@inertiajs/react";
import {capitalize} from "lodash"
import {prettyDate} from "@metronic/helpers/utils.jsx"
import clsx from "clsx";
import {Tooltip} from "react-tooltip"

const ChatList = ({ticketsList}) => {
  const {props: pageProps} = usePage()

  const openTicket = (id) => {
    router.get('/support', {id: id})
  }

  return (
    <>
      <Card className='card-flush'>
        <CardHeader className='pb-3 pt-4 d-flex ' id='kt_chat_contacts_header'>
          <div className="card-title fs-3 fw-bold">Chats list</div>
          <Link
            id="support_create_ticket_button"
            href={route('support.create')}
            className="btn btn-icon btn-light-success"
            data-tooltip-content={'Create new ticket'}
            data-tooltip-id={'ticket-create'}
          >
            <span className="svg-icon svg-icon-1">
              <i className="bi bi-plus-square fs-1"></i>
            </span>
          </Link>
          <Tooltip id={'ticket-create'}/>
        </CardHeader>

        <div className='border border-dashed'></div>

        <CardBody className='pt-0 px-0' id='kt_chat_contacts_body'>
          <div
            className={clsx('scroll-y me-n5 pe-5', ticketsList?.length ? 'h-175px h-lg-auto' : 'h-50px h-lg-auto')}
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
            data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
            data-kt-scroll-offset='0px'
          >

            {/* BEGIN tickets loop */}
            {(ticketsList && ticketsList?.length) ? ticketsList.map((i, idx) => {
              const statusColor = i.status_color === 'secondary' ? 'primary' : i.status_color

              return <div key={idx} className={clsx('', i.id === pageProps?.active_ticket?.id ? 'bg-light-primary' : 'bg-body')}>
                <div
                  id={`support_list_ticket_${idx + 1}`}
                  className='d-flex flex-stack py-2 px-3 cursor-pointer bg-hover-light'
                  onClick={() => openTicket(i.id)}
                >
                  <div className='d-flex align-items-center text-nowrap text-truncate'>
                    <div className='symbol symbol-45px symbol-circle'>
                        <span className={
                          clsx(`symbol-label fs-4 fw-bolder bg-light-${statusColor} text-${statusColor}`)
                        }>
                          {capitalize(i.subject.split('', 1)[0])}
                        </span>
                    </div>

                    <div className='ms-5'>
                      <span className='fs-7 fw-bolder text-gray-900 mb-2'>{i.subject}</span>
                      <div className='fw-bold fs-8 text-gray-500'>{prettyDate(i.created_at)}</div>
                    </div>
                  </div>

                  <div className='d-flex flex-column align-items-end ms-2 text-nowrap'>
                    <span
                      className={`d-none d-sm-block text-inverse-${statusColor} bg-${statusColor} rounded-2 fw-bold px-1 fs-8 mb-1`}
                    >
                      {i.status_name}
                    </span>
                    <span
                      className={`d-block d-sm-none text-inverse-${statusColor} bg-${statusColor} h-20px w-20px rounded-circle fw-bold px-1 fs-8 mb-1`}
                    >
                    </span>
                  </div>
                </div>

                <div className='separator separator-dashed'></div>
              </div>
            }) :
              <div className="text-gray-600 fs-4 w-100 fw-bold text-center pt-8">
                Chats list is empty
              </div>
            }
            {/* END tickets loop */}

          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default ChatList
