import React from 'react'
import {Link} from "@inertiajs/react"
import {clsx} from "clsx"
// Context
import {useSecuritiesContext} from "@metronic/Components/Securities/Context/SecuritiesContext"
import {IS_LIGHT_THEME, VITE_APP_VARIANT} from "@metronic/helpers/utils.jsx"
import {useThemeMode} from "@metronic/_metronic/partials/layout/theme-mode/ThemeModeProvider"
import {AppVariant} from "@metronic/Enums/AppVariants/AppVariant.js"

const GeneralTabs = () => {
  const {activeTab, setActiveTab, tabList} = useSecuritiesContext()
  const {mode} = useThemeMode()

  return (
    <>
      {(tabList && tabList.length) ?
        <>
          <div className={clsx('d-flex flex-sm-row justify-content-start rounded-4 h-60px',
            ((VITE_APP_VARIANT === AppVariant.PLANNER_ORANGE || VITE_APP_VARIANT === AppVariant.PLANNER_ORANGE) &&
            mode === 'dark') ? 'mt-3' : ''
            // VITE_APP_VARIANT === 'light' ? 'justify-content-lg-end' : 'justify-content-end'
          )}>
            {/*<div className="d-flex align-items-center justify-content-center ms-5">*/}
            {/*  <h3 className="text-primary mb-0">{tabList.find(i => i.id === activeTab)?.title}</h3>*/}
            {/*</div>*/}

            {/*<div className="d-flex align-items-center ps-6 bg-body rounded-3">*/}
            <ul
              className='nav nav-tabs border-0 nav-line-tabs nav-stretch fs-6 overflow-x-auto fw-bolder ps-6 flex-nowrap bg-body rounded-3'
              style={{scrollbarGutter: 'stable'}}
            >
              {
                tabList.map((tab, idx) => {
                  let tabId = String(tab.id)

                  return (
                    <li className='nav-item mb-0' key={idx}>
                      <Link
                        id={`general_security_tab_${idx + 1}`}
                        onClick={() => setActiveTab(tabId)}
                        className={clsx('nav-link me-6 py-0 border-bottom-2 text-center',
                          activeTab === tabId ? 'active text-gray-800' : 'text-gray-600',
                          IS_LIGHT_THEME ? 'fs-lg-7 fs-xl-6' : ''
                        )}
                        data-bs-toggle="tab"
                      >
                        {tab.title}
                      </Link>
                    </li>
                  )
                })
              }
            </ul>
            {/*</div>*/}
          </div>
          <div className="mb-3 pt-3"></div>
        </> : null
      }
    </>
  )
}

export default GeneralTabs
