import React, {useEffect, useState} from 'react'
import {Button, Form} from "reactstrap"
import {router, useForm, usePage} from "@inertiajs/react"
import _ from "lodash"
import {toast, ToastContainer} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import {clsx} from "clsx"
import {get} from "@metronic/helpers/api_helper.jsx"
import {VITE_APP_VARIANT} from "@metronic/helpers/utils.jsx"
import {AppVariant} from "@metronic/Enums/AppVariants/AppVariant.js"

//Images
const imgUrl = '/storage/content/login_img/'
const asideImage = imgUrl + 'aside_image.png'
const bgImage = imgUrl + 'bg_image.png'
const rotatingImage = imgUrl + 'rotating_image.svg'

//Default images
import defaultAsideImg from '@metronic/_metronic/assets/media/auth/22.png'
import defaultBgImg from '@metronic/_metronic/assets/media/auth/bg12.jpg'

//Logo
const logosUrl = '/storage/brand_logos/'
const logoDarkUrl = logosUrl + 'logo-dark.png'
const logoLightUrl = logosUrl + 'logo-light.png'

//Importing page texts
// import pageTexts from '../../../../storage/app/public/content/login.json'

//Styles
let bgPosition = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: 'auto',
  objectFit: "cover",
  zIndex: '0'
}

let overlay = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  zIndex: '1'
}

let btnPrimary = {
  backgroundColor: '#007bff',
  color: 'white',
  border: 'none',
  padding: '10px 20px',
  fontSize: '16px',
  cursor: 'pointer',
  borderRadius: '4px',
  transition: 'background-color 0.3s',
}

let btnPrimaryHover = {
  backgroundColor: '#0056b3',
}

let btnClicked = {
  backgroundColor: '#7db9f4',
  color: 'white',
}

let rotatingStyle = {
  animation: 'rotate 20s linear infinite',
}

const keyframesStyle = `
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}`

const LogInContent = () => {
  const {props} = usePage()
  const flash = props.flash
  const [isMouseOnButton, setIsMouseOnButton] = useState(false)
  const [isButtonClicked, setIsButtonClicked] = useState(false)
  const [isPasswordShaw, setIsPasswordShaw] = useState(false)
  const [isRotatingImgBlock, setIsRotatingImgBlock] = useState(true)
  const [pageTexts, setPageTexts] = useState({})
  const { data, setData, post, processing, errors } = useForm({
    email: '',
    password: '',
    remember_me: false,
  })
  const mode = localStorage.getItem('kt_theme_mode_value')
  const [logoVariant, setLogoVariant] = useState('')
  const [modeStyles, setModeStyles] = useState({})

  const submitHandler = (e) => {
    e.preventDefault()
    const btn = e.target.querySelector('[type="submit"]')
    btn.disabled = true

    post('/login')

    btn.disabled = false
    setIsButtonClicked(false)
  }

  useEffect(() => {
    if (_.size(props.errors)) {
      for (let error in props.errors) {
        toast.error(props.errors[error])
      }
    } else if (flash) {
      toast.success(flash.message)
    }
  }, [props])

  useEffect(() => {
    get('/storage/content/login.json').then(r => setPageTexts(r))
  }, [])

  useEffect(() => {
    if (mode === 'dark') {
      setLogoVariant(logoLightUrl)
      setModeStyles({
        textColor: '#F9F9F9',
        cardBg: 'bg-dark'
      })
      overlay = {...overlay, backgroundColor: 'rgba(1, 1, 1, 0.6)'}
    } else {
      setLogoVariant(logoDarkUrl)
      setModeStyles({
        textColor: '#252F4A',
        cardBg: 'bg-white'
      })
      overlay = {...overlay, backgroundColor: 'rgba(255, 255, 255, 0.5)'}
    }
  }, [mode])

  useEffect(() => {
    if (VITE_APP_VARIANT === AppVariant.PLANNER_ORANGE) {
      btnPrimary = {...btnPrimary, backgroundColor: '#FF5300'}
      btnClicked = {...btnClicked, backgroundColor: '#ff8b4e'}
      btnPrimaryHover = {...btnPrimaryHover, backgroundColor: '#e34f02'}
    } else {
      btnPrimary = {...btnPrimary, backgroundColor: '#007bff'}
      btnClicked = {...btnClicked, backgroundColor: '#7db9f4'}
      btnPrimaryHover = {...btnPrimaryHover, backgroundColor: '#0056b3'}
    }
  }, [VITE_APP_VARIANT])

  return (
    <>
      <ToastContainer />
      <style>{keyframesStyle}</style>
      <div className="d-flex flex-column flex-lg-row flex-column-fluid position-relative">
        <img
          alt='bg'
          className="img-fluid w-100 h-100"
          src={bgImage}
          style={bgPosition}
          onError={(e) => e.target.src = defaultBgImg}
        />
        <div style={overlay}>
        </div>
        <div
          className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 z-index-1">
          <div className={`${modeStyles?.cardBg} d-flex flex-column flex-center rounded-4 w-450px w-md-600px p-10`}>
            <div className="d-flex flex-center flex-column align-items-stretch h-lg-100 w-100 w-md-400px">
              <div className="d-flex flex-center flex-column flex-column-fluid pb-15 pb-lg-20 w-100">
                <Form onSubmit={e => submitHandler(e)} className="form w-100">
                  <div className="text-center fw-bolder pt-20 pb-20">
                    <img className="mx-auto mw-100 w-150px w-lg-300px"
                         src={logoVariant} alt=""/>
                    <h1 className="mt-5" style={{color: modeStyles?.textColor}}>Welcome back!</h1>
                  </div>
                  <div className="text-center mb-11">
                    <h2 className="fw-bolder mb-3" style={{color: modeStyles?.textColor}}>Sign In</h2>
                    {/*<div className="text-gray-500 fw-semibold fs-6">Your Social Campaigns</div>*/}
                  </div>
                  {/*<div className="row g-3 mb-9">*/}
                  {/*  <div className="col-md-6">*/}
                  {/*    <a href="#"*/}
                  {/*       className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">*/}
                  {/*      <img alt="Logo" src="assets/media/svg/brand-logos/google-icon.svg" className="h-15px me-3"/>Sign*/}
                  {/*      in with Google</a>*/}
                  {/*  </div>*/}
                  {/*  <div className="col-md-6">*/}
                  {/*    <a href="#"*/}
                  {/*       className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">*/}
                  {/*      <img alt="Logo" src="assets/media/svg/brand-logos/apple-black.svg"*/}
                  {/*           className="theme-light-show h-15px me-3"/>*/}
                  {/*      <img alt="Logo" src="assets/media/svg/brand-logos/apple-black-dark.svg"*/}
                  {/*           className="theme-dark-show h-15px me-3"/>Sign in with Apple</a>*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  <div className="fv-row mb-8">
                    <input
                      id="login_input_email"
                      type="text"
                      style={{color: modeStyles?.textColor}}
                      value={data.email}
                      placeholder="Email"
                      name="email"
                      autoComplete="off"
                      className="form-control bg-transparent"
                      onChange={e => setData('email', e.target.value)}
                    />
                  </div>
                  <div className="fv-row mb-3 position-relative">
                    <input
                      id="login_input_password"
                      type={clsx(isPasswordShaw ? 'text' : 'password')}
                      value={data.password}
                      placeholder="Password"
                      name="password"
                      style={{color: modeStyles?.textColor}}
                      autoComplete="off"
                      className="form-control bg-transparent"
                      onChange={e => setData('password', e.target.value)}
                    />
                    <Button
                      color="link"
                      id="login_button_show_pass"
                      onClick={() => setIsPasswordShaw(!isPasswordShaw)}
                      className="position-absolute end-0 top-0 text-decoration-none text-muted"
                      type="button"
                    >
                      <i
                        className={clsx('bi', !isPasswordShaw ? 'bi-eye-fill' : 'bi-eye-slash-fill', 'pb-1 pe-3 fs-2')}
                        style={{color: '#99a1b7'}}
                      ></i>
                    </Button>
                  </div>
                  <div className="d-flex flex-wrap fs-base fw-semibold mt-5">
                    <div></div>
                    <label className="cursor-pointer">
                      <input
                        style={{backgroundColor: btnPrimary?.backgroundColor}}
                        className="cursor-pointer"
                        type="checkbox"
                        name="remember"
                        id="login_remember"
                        value={data.remember_me}
                        onChange={(e) => setData('remember_me', e.target.checked)}
                      />
                      <span className="fw-bold ps-2">Remember me</span>
                    </label>
                  </div>
                  <div className="separator mb-14 mt-11"></div>
                  <div className="d-grid mb-10">
                    <button
                      id="login_submit_button"
                      type="submit"
                      style={isButtonClicked ? {...btnPrimary, ...btnClicked} : isMouseOnButton ?
                        {...btnPrimary, ...btnPrimaryHover} : btnPrimary}
                      onMouseEnter={() => setIsMouseOnButton(true)}
                      onMouseLeave={() => setIsMouseOnButton(false)}
                      onClick={() => setIsButtonClicked(true)}
                    >
                      Sign In
                    </button>
                  </div>
                  {/*<div className="text-gray-500 text-center fw-semibold fs-6">Not a Member yet?*/}
                  {/*  <a href="authentication/layouts/overlay/sign-up.html" className="link-primary">Sign up</a></div>*/}
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-lg-row-fluid z-index-1 mb-15 mb-lg-0">
          <div className="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
            <div className="position-relative d-flex justify-content-center">
              {isRotatingImgBlock ?
                <img
                  className="mx-auto mw-100 w-150px w-lg-300px position-absolute top-0 left-0"
                  src={rotatingImage}
                  alt="img"
                  style={rotatingStyle}
                  onError={() => setIsRotatingImgBlock(false)}
                /> : null
              }
              <img
                className="mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20 position-sticky"
                src={asideImage}
                onError={(e) => e.target.src = defaultAsideImg}
                alt="img"
              />
            </div>
            <div className="mw-600px">
              <h1 className="fs-2qx fw-bold text-center mb-7" style={{color: modeStyles?.textColor}}>
                {pageTexts.title || ""}
              </h1>
              <div className="text-center fs-5 fw-semibold" style={{color: modeStyles?.textColor}}>
                {pageTexts.description || ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LogInContent
