import React, {useContext, useEffect, useRef, useState} from 'react'
import {Button, Col, Form, Input, Label, Row} from "reactstrap"
import Select from "react-select"
import {useTranslation} from "react-i18next"
import {ModalContext} from "@metronic/Components/Modal/ModalsContext/ModalContext.jsx"
import {selectListData} from "@metronic/api/general.js";
import {useForm} from "@inertiajs/react";

const AddDocumentModal = () => {
  const [selectedType, setSelectedType] = useState('')
  const [documentTypeOptions, setDocumentTypeOptions] = useState([])
  const unloadedFile = useRef('')
  const { t } = useTranslation()
  const {closeModal} = useContext(ModalContext)

  const {data, setData, post, reset} = useForm({
    type: '',
    attachments: '',
  })

  const submitHandler = (e) => {
    e.preventDefault()

    post('/profile/add-documents', {
      preserveScroll: true,
    })

    closeModal()
  }

  useEffect(() => {
    selectListData('document_types').then(r => {
      setDocumentTypeOptions(r)
    })
  }, [])

  return (
    <>
      <Form onSubmit={submitHandler}>
        <Row>
          <Col xs={12} className='mb-3'>
            <Label className="form-label mb-0">{t("Document type")}</Label>
            <Select
              id="download_doc_type_select"
              placeholder={t("Choose...")}
              name="type"
              value={selectedType}
              onChange={(selectedOptions) => {
                setSelectedType(selectedOptions)
                setData('type', selectedOptions.value)
              }}
              options={documentTypeOptions}
              required
            />
          </Col>
          <Col xs={12}>
            <Label className="form-label mb-0">{t("Files")}</Label>
            <Input
              id="download_doc_input"
              type='file'
              name="attachments"
              multiple={true}
              placeholder="Upload document..."
              onChange={(e) => {
                unloadedFile.current = e.target.files
                setData('attachments', e.target.files)
              }}
            >
            </Input>
          </Col>
          <Col xs={12} className="hstack justify-content-end pt-5">
            <Button
              id="download_doc_submit"
              type="submit"
              color="primary"
              className="btn px-3 py-2 ms-auto mt-4"
            >
              {t("Submit")}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default AddDocumentModal
