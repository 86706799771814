
import React, {FC, useEffect, useState} from 'react'
import {Link, router, usePage} from '@inertiajs/react'
// import {useAuth} from '@metronic/modules/auth'
// import {Languages} from '@metronic/_metronic/partials/layout/header-menus/Languages'
// import {KTIcon, toAbsoluteUrl} from '@metronic/_metronic/helpers'
// import {Tooltip} from "react-tooltip"
// Default Avatar
import defaultAvatar from '@metronic/assets/images/users/user-dummy-img.jpg'
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap"
import clsx from "clsx"
import {KTIcon} from "../../../helpers"
import {VITE_APP_VARIANT, IS_LIGHT_THEME} from "@metronic/helpers/utils.jsx"
import {AppVariant} from '../../../../Enums/AppVariants/AppVariant'

const HeaderUserMenu: FC = () => {
  const {props: pageProps} = usePage()
  const userData = pageProps.auth.user
  const [isDropdown, setIsDropdown] = useState(false)
  const [isProfileActive, setIsProfileActive] = useState(false)
  const [isSignOutActive, setIsSignOutActive] = useState(false)
  const [isWalletActive, setIsWalletActive] = useState(false)

  const logout = () => {
    router.post('/logout')
  }

  const toggleDropdown = () => {
    setIsProfileActive(false)
    setIsDropdown(!isDropdown)
  }

  return (
    // <div
    //   className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px'
    //   data-kt-menu='true'
    // >
    <Dropdown
      isOpen={isDropdown}
      toggle={toggleDropdown}
    >
      <DropdownToggle
        type="button"
        tag="button"
        className="btn btn-icon"
        id="profile_dropdown_button"
      >
        {(VITE_APP_VARIANT === AppVariant.PLANNER || VITE_APP_VARIANT === AppVariant.PLANNER_ORANGE) ?
          (pageProps?.auth?.user?.profile?.avatar_url ?
            <img
              className='h-30px w-30px rounded object-fit-cover'
              src={pageProps?.auth?.user?.profile?.avatar_url ? pageProps?.auth?.user?.profile?.avatar_url : defaultAvatar}
              alt='avatar'
            /> :
            <KTIcon iconName='user' className="fs-1"/>) :
          <img
            className='h-30px w-30px rounded object-fit-cover'
            src={pageProps?.auth?.user?.profile?.avatar_url ? pageProps?.auth?.user?.profile?.avatar_url : defaultAvatar}
            alt='avatar'
          />
        }

      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-lg py-0">
        <div className="menu-item px-3">
          <div className="menu-content d-flex align-items-center px-3">
            <div className="symbol symbol-50px me-5">
              <img className="object-fit-cover" alt='Logo'
                   src={userData?.profile?.avatar_url ? userData?.profile?.avatar_url : defaultAvatar}/>
            </div>

            <div className='d-flex flex-column'>
              <div className='fw-bold d-flex fs-5'>
                {userData?.name}
              </div>
              <span className='fw-semibold text-muted fs-7'>
              {userData?.email}
            </span>
            </div>
          </div>
        </div>

        {!IS_LIGHT_THEME ?
          <>
            <div className='separator my-2'></div>

            <div className='menu-item px-5'>
              <Link
                id="profile_dropdown_wallet"
                href={'/wallet'}
                onClick={toggleDropdown}
                className={clsx(`menu-link px-5 fw-bold`, isWalletActive ? 'bg-light-dark rounded-2' : '')}
                onMouseEnter={() => setIsWalletActive(true)}
                onMouseLeave={() => setIsWalletActive(false)}
              >
                My Wallet
              </Link>
            </div>
          </> : null
        }

        <div className='separator my-2'></div>

        <div className='menu-item px-5'>
          <Link
            id="profile_dropdown_profile"
            href={'/profile'}
            onClick={toggleDropdown}
            className={clsx(`menu-link px-5 fw-bold`, isProfileActive ? 'bg-light-dark rounded-2' : '')}
            onMouseEnter={() => setIsProfileActive(true)}
            onMouseLeave={() => setIsProfileActive(false)}
          >
            My Profile
          </Link>
        </div>

        <div className='separator my-2'></div>

        {/*<Languages />*/}

        {/*<div className='menu-item px-5 my-1'>*/}
        {/*  <Link href='/crafted/account/settings' className='menu-link px-5'>*/}
        {/*    Account Settings*/}
        {/*  </Link>*/}
        {/*</div>*/}

        <div className='menu-item px-5 pb-3'>
          <a
            id="profile_dropdown_sign_out"
            onClick={logout}
            className={clsx(`menu-link px-5 fw-bold`, isSignOutActive ? 'bg-light-dark rounded-2' : '')}
            onMouseEnter={() => setIsSignOutActive(true)}
            onMouseLeave={() => setIsSignOutActive(false)}
          >
            Sign Out
          </a>
        </div>

      </DropdownMenu>
    </Dropdown>
    // </div>
  )
}

export {HeaderUserMenu}
