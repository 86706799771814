import React, {useContext, useEffect, useState} from 'react'
import {ModalContext} from "@metronic/Components/Modal/index.jsx"
import {Col, Input, Label, Row} from "reactstrap"
import {toast} from "react-toastify"
import {formatSecuritiesPrice, isTwoPrecision, reloadData} from "@metronic/helpers/utils.jsx"
import {selectListData} from "@metronic/api/general.js"
import {sellSecurity} from "@metronic/api/securities.js"
import _ from "lodash"

const SellSecuritiesModal = (props) => {
  const {
    acquiredSecurity,
    updateDetailsTable,
    securitiesList,
    ownSecuritiesList,
    setActiveTab,
    allListTab,
    setOwnSelectedSecurities
  } = props
  const {closeModal} = useContext(ModalContext)
  const [quantity, setQuantity] = useState('')
  const [availableQuantity, setAvailableQuantity] = useState('')
  const [payoutAssetId, setPayoutAssetId] = useState('')
  const [payoutAsset, setPayoutAsset] = useState('')
  const [securityPrice, setSecurityPrice] = useState(0)

  const sellSecuritiesOrder = () => {
    const newList = ownSecuritiesList.find(i => i.id !== acquiredSecurity?.security?.id)

    sellSecurity({customer_security_id: acquiredSecurity?.security_id, quantity: quantity}).then(r => {
      if (r.success) {
        toast.success(r.message)

        reloadData(['customer_securities', 'account_balance'])

        if ((Number(quantity) === Number(availableQuantity)) && !_.isEmpty(newList)) {
          setOwnSelectedSecurities(newList[0])
        }

        if ((Number(quantity) === Number(availableQuantity)) && _.isEmpty(newList) && allListTab) {
          setActiveTab(allListTab)
        }

        closeModal()
        updateDetailsTable()
      } else {
        toast.error(r.message)
      }
    }).catch(r => {
      toast.error(r)
    })
  }

  const getPayoutAsset = (id) => {
    selectListData('assets_list').then(r => {
      setPayoutAsset(r.find(i => i.value === Number(id)))
    })
  }

  useEffect(() => {
    if (acquiredSecurity) {
      setAvailableQuantity(acquiredSecurity.quantity)
      setPayoutAssetId(acquiredSecurity?.security?.fields?.payout_asset?.value)
    }
  }, [acquiredSecurity])

  useEffect(() => {
    if (payoutAssetId) {
      getPayoutAsset(payoutAssetId)
    }
  }, [payoutAssetId])

  useEffect(() => {
    if (payoutAsset && acquiredSecurity && !_.isEmpty(securitiesList)) {
      setSecurityPrice(Number(acquiredSecurity?.security?.price || 0))
    }
  }, [payoutAsset, acquiredSecurity, securitiesList])

  return (
    <>
      <Row className="border-top pt-5">
        <Col>
          <h1 className="text-center">{acquiredSecurity?.security?.name}</h1>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <div
            className="bg-light-warning w-100 text-center h-auto rounded-2 d-flex align-items-center justify-content-center">
            <span className="fs-6 fw-bold text-gray-600 p-5">
              You have selected the security <span className="text-primary">"{acquiredSecurity?.security?.name}"</span>.
Enter the quantity you wish to sell and be aware that the price displayed may differ from the actual sale price.
Once confirmed, the corresponding amount will be credited to your account, and the sold securities will be removed from your portfolio.
            </span>
          </div>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="col-6">
          <label className="mb-0 form-label fw-bold text-center w-100">Available Quantity</label>
          <input
            id="sell_sec_modal_limit_field"
            type="text"
            className="form-control form-control-solid fw-bold text-center"
            placeholder="Limit"
            disabled
            value={availableQuantity || 'No data'}
            onChange={() => {}}
          />
        </Col>
        <Col className="col-6">
          <label className="mb-0 form-label fw-bold text-center w-100">Payout asset</label>
          <input
            id="sell_sec_modal_asset_field"
            type="text"
            className="form-control form-control-solid fw-bold text-center"
            placeholder="Payout asset"
            disabled
            value={payoutAsset ? `${payoutAsset?.name}/${payoutAsset?.label}` : 'United States Dollar/USD'}
            onChange={() => {}}
          />
        </Col>
      </Row>

      <Row className="mt-5 pb-5 border-bottom-dashed border-1 border-gray-500">
        <Col className="col-12 d-flex flex-column">
          <div className="d-flex align-items-end">
            <div className="w-100">
              <Label className="mb-0 fw-bold">Quantity for sale</Label>
              <Input
                id="sell_sec_modal_quantity_field"
                type="text"
                className="form-control fw-bold text-gray-800 w-100 fs-4"
                autoComplete="off"
                placeholder="Enter the quantity"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </div>
            <div className="ms-3">
              <button
                id="sell_sec_modal_submit_order_button"
                type="button"
                className="btn btn-light-primary fs-3 fw-bold ms-2 text-nowrap"
                onClick={() => sellSecuritiesOrder()}
              >
                Create order
              </button>
            </div>
          </div>

          <div className="text-center w-100 py-3 fw-bold fs-4">
            Price: <span className="fw-bold text-primary">
            {formatSecuritiesPrice(payoutAsset?.label, securityPrice.toFixed(2) || '0.00')}
          </span>,
            Total sales amount: <span className="fw-bold text-primary">
            {isTwoPrecision(payoutAsset?.label, (securityPrice * Number(quantity)))}
            {payoutAsset?.label ? <span className="text-gray-600 ms-2 me-2">{payoutAsset?.label}</span> : 'USD'}
            ({formatSecuritiesPrice('USD', ((securityPrice * Number(quantity)) * payoutAsset?.price).toFixed(2) || '0.00')})
          </span>
          </div>
        </Col>
      </Row>

      <Row className="mt-6">
        <Col>
          <div className="d-flex justify-content-start">
            <button
              id="sell_sec_modal_cancel_button"
              type="button"
              className="btn btn-light-warning fs-3 py-2 fw-bold me-2"
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default SellSecuritiesModal
