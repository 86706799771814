import React from 'react'
import {Table} from "reactstrap";
import {
  assetFullName,
  cryptoIconsMapper,
  isTwoPrecision,
  prettyDate,
  TransactionStatusBadge
} from "@metronic/helpers/utils.jsx"

const OrderDetailsModal = ({order}) => {

  return (
    <>
      {order ?
        <Table responsive className="table-striped mb-3">
          <tbody>
          <tr className="fw-bold">
            <td>Order ID</td>
            <td id="order_details_modal_id" className="text-gray-700">#{order.id}</td>
          </tr>
          <tr className="fw-bold">
            <td>Transaction ID</td>
            <td id="order_details_modal_transaction_id" className="text-gray-700">#{order.transfer_id ?? '-'}</td>
          </tr>
          <tr className="fw-bold">
            <td>Sell asset</td>
            <td id="order_details_modal_sell_asset" className="text-gray-700">
              <div className="d-flex align-items-center">
                <div className="h-25px w-25px rounded-circle me-2">
                  <img
                    src={cryptoIconsMapper[order.sell_asset?.currency?.code]}
                    className="img-fluid"
                    alt="3"
                  />
                </div>
                <span className="text-gray-700 text-truncate">{assetFullName[order.sell_asset?.currency?.code]}</span>
              </div>
            </td>
          </tr>
          <tr className="fw-bold">
            <td>Sell amount</td>
            <td
              id="order_details_modal_sell_amount"
              className="text-gray-700">{isTwoPrecision(order.sell_asset?.currency?.code, order.sell_price)}</td>
          </tr>
          <tr className="fw-bold">
            <td>Buy asset</td>
            <td id="order_details_modal_buy_asset" className="text-gray-700">
              <div className="d-flex align-items-center">
                <div className="h-25px w-25px rounded-circle me-2">
                  <img
                    src={cryptoIconsMapper[order.buy_asset?.currency?.code]}
                    className="img-fluid"
                    alt="3"
                  />
                </div>
                <span className="text-gray-700 text-truncate">{assetFullName[order.buy_asset?.currency?.code]}</span>
              </div>
            </td>
          </tr>
          <tr className="fw-bold">
            <td>Buy amount</td>
            <td
              id="order_details_modal_buy_amount"
              className="text-gray-700">{isTwoPrecision(order.buy_asset?.currency?.code, order.buy_price)}</td>
          </tr>
          <tr className="fw-bold">
            <td>Status</td>
            <td
              id="order_details_modal_status"
              className="text-gray-700">
              <TransactionStatusBadge status={order.order_status} color={order.order_color} />
            </td>
          </tr>
          <tr className="fw-bold">
            <td>Created at</td>
            <td
              id="order_details_modal_created_at"
              className="text-gray-700">{prettyDate(order.created_at)}</td>
          </tr>
          </tbody>
        </Table>
        : null
      }
    </>
  )
}

export default OrderDetailsModal
