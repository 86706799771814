import React, {useContext, useState} from 'react'
import {Col, Row, Spinner, Table} from "reactstrap"
import {
  cryptoIconsMapper,
  isTwoPrecision,
  prettyDate,
  printTransactionType,
  toTwoPrecision,
  TransactionStatusBadge
} from "@metronic/helpers/utils.jsx"
import {ModalContext} from "@metronic/Components/Modal/ModalsContext/ModalContext.jsx"
import {useTranslation} from "react-i18next"
import _, {capitalize} from "lodash"
import {TransferType} from "@metronic/Enums/Transfers/TransferType.js";

const TransactionsDetailsModal = ({selectedTransaction}) => {
  const {closeModal} = useContext(ModalContext)
  const {t} = useTranslation()
  const [isUrlsValid, setIsUrlsValid] = useState([])

  const transaction = selectedTransaction

  const baseAssetCode = transaction?.base_asset?.currency?.code
  const quoteAssetCode = transaction?.quote_asset?.currency.code
  const type = transaction?.type
  const quoteAmount = transaction?.quote_amount
  const baseAmount = transaction?.base_amount
  const totalFee = transaction?.total_fee
  const usdAmount = transaction?.usd_amount
  const status = transaction?.status || ''
  const security = transaction?.security || {}
  const additionalData = transaction?.meta?.additional_data

  return (
    <React.Fragment>
      {selectedTransaction ?
        (
          <Row>
            <Col className={`${!_.isEmpty(additionalData) ? 'col-12 col-lg-6' : 'col-12'}`}>
            <Table responsive className="table-striped mb-3">
              <tbody>
              <tr className="fw-bold">
                <td>ID</td>
                <td id="transac_det_modal_id_val" className="text-gray-700">#{transaction.id}</td>
              </tr>
              <tr className="fw-bold">
                <td>{transaction.invoice_id ? 'Payout Asset' : 'Base Asset'}</td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="h-25px w-25px rounded-circle me-2">
                      <img
                        src={cryptoIconsMapper[baseAssetCode]}
                        className="img-fluid"
                        alt="3"
                      />
                    </div>
                    <span id="transac_det_modal_base_asset_val" className="text-gray-700">{baseAssetCode}</span>
                  </div>
                </td>
              </tr>
              {type === TransferType.EXCHANGE ?
                <>
                  <tr className="fw-bold">
                    <td>{t("Buy amount")}</td>
                    <td id="transac_det_modal_exc_buy_amount_val" className="text-gray-700">
                      {isTwoPrecision(baseAssetCode, baseAmount)}
                    </td>
                  </tr>
                  <tr className="fw-bold">
                    <td>{t("Quote Asset")}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="h-25px w-25px rounded-circle me-2">
                          <img
                            src={cryptoIconsMapper[quoteAssetCode]}
                            className="img-fluid"
                            alt="3"
                          />
                        </div>
                        <span id="transac_det_modal_exc_quote_asset_val" className="text-gray-700">{quoteAssetCode}</span>
                      </div>
                    </td>
                  </tr>
                  <tr className="fw-bold">
                    <td>{t("Sell amount")}</td>
                    <td id="transac_det_modal_exc_sell_amount_val" className="text-gray-700">
                      {isTwoPrecision(quoteAssetCode, quoteAmount)}
                    </td>
                  </tr>
                </>
                :
                <tr className="fw-bold">
                  <td>{t("Amount")}</td>
                  <td id="transac_det_modal_amount_val" className="text-gray-700">
                    {isTwoPrecision(baseAssetCode, baseAmount)}
                  </td>
                </tr>
              }

                {!_.isEmpty(security) ?
                  <>
                    <tr className='fw-bold'>
                      <td>{t('Quote Asset')}</td>
                      <td>
                        <div className="d-flex align-items-center fw-bold text-gray-700">
                          <div className='symbol symbol-25px symbol-circle me-2'>
                            {(security?.meta?.fields?.logo?.value && !isUrlsValid.includes(String(security.id))) ?
                              <img
                                src={security?.meta?.fields?.logo?.value}
                                className='align-self-end'
                                alt='image'
                                onError={() => setIsUrlsValid(prev => ([...prev, String(security.id)]))}
                              /> :
                              <div className="symbol-label fs-2 fw-semibold text-primary">
                                {capitalize(security?.meta?.name.slice(0, 1))}
                              </div>
                            }
                          </div>
                          <div id="transac_det_modal_sec_quote_asset_val" className="cursor-default text-truncate">
                            {security?.meta?.name}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className='fw-bold'>
                      <td>Quantity</td>
                      <td id="transac_det_modal_sec_quantity_val" className='text-gray-700'>{transaction?.meta?.quantity}</td>
                    </tr>
                  </> : null
                }

                <tr className="fw-bold">
                  <td>{t("Type")}</td>
                  <td id="transac_det_modal_type_val" className="text-gray-700">
                    {t(`${
                      printTransactionType(capitalize(transaction.invoice_id && Number(usdAmount) < 0 ? 'invoice' : type))}`)}
                  </td>
                </tr>
                <tr className="fw-bold">
                  <td>{t("Status")}</td>
                  <td id="transac_det_modal_status_val">
                    <TransactionStatusBadge status={status} color={transaction.status_color} />
                  </td>
                </tr>
                <tr className="fw-bold">
                  <td>{t("Fee")}</td>
                  <td id="transac_det_modal_fee_val" className="text-gray-700">
                    {transaction.invoice_id ? '0%' : (
                      <span>
                      {Number(transaction.fee_pct)}%
                      {type === TransferType.DEDUCTION ? (
                        <span>&nbsp;({isTwoPrecision(baseAssetCode, totalFee)} {baseAssetCode})</span>
                      ) : (
                        type === TransferType.EXCHANGE ? (
                          <span>
															&nbsp;({isTwoPrecision(quoteAssetCode, totalFee)} {quoteAssetCode})
														</span>
                          ) : null
                        )}
   										 </span>
                  )}
                </td>
              </tr>
              {type === TransferType.EXCHANGE ? (
                <>
                  <tr className="fw-bold">
                    <td>{t("Buy amount in USD")}</td>
                    <td id="transac_det_modal_amount_in_usd_val" className="text-gray-700">{toTwoPrecision(usdAmount)}$</td>
                  </tr>
                  <tr className="fw-bold">
                    <td>{t("Total sell amount")}</td>
                    <td id="transac_det_modal_total_sell_amount_val" className="text-gray-700">
                      {isTwoPrecision(quoteAssetCode,
                        (Number(quoteAmount) + Number(totalFee)))}
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr className="fw-bold">
                    <td>{t("USD Amount")}</td>
                    <td id="transac_det_modal_usd_amount_val" className="text-gray-700">{toTwoPrecision(usdAmount)}$</td>
                  </tr>
                  <tr className="fw-bold">
                    <td>{t("Total")}</td>
                    {type === TransferType.DEDUCTION ?
                      <td id="transac_det_modal_total_deduction_val" className="text-gray-700">
                        {isTwoPrecision(baseAssetCode, (Number(baseAmount) + Number(totalFee)))}
                      </td>
                      :
                      <td id="transac_det_modal_total_val" className="text-gray-700">{isTwoPrecision(baseAssetCode, baseAmount)}</td>
                    }
                  </tr>
                </>
              )
              }

                <tr className="fw-bold">
                  <td>{t("Date")}</td>
                  <td id="transac_det_modal_date_val">{prettyDate(transaction.created_at)}</td>
                </tr>
                </tbody>
              </Table>
            </Col>

            <Col className={`${!_.isEmpty(additionalData) ? 'col-12 col-lg-6 pt-3 pt-lg-0' : 'd-none'}`}>
              <span className='text-muted fw-bold'>Billing info</span>
              {!_.isEmpty(additionalData) ? (
                <Table responsive className="table-striped">
                  <tbody>
                  {Object.entries(additionalData).map(([key, value], idx) => {
                    return (
                      key === 'UserId'
                        ? null :
                        <tr key={idx}>
                          <td className="fw-semibold font-italic text-gray-800 fw-bold">{key}:</td>
                          <td
                            id={`transac_det_modal_addit_data_${String(key).replace(/ /g, '_')}`}
                            className="text-gray-800 fw-bold"
                          >
                            {value}
                          </td>
                        </tr>
                    )
                  })}
                  </tbody>
                </Table>
              ) : null}
            </Col>

            <Col className='col-12'>
              {transaction?.meta?.comment ? (
                <div className="border-top-dashed border-bottom-dashed border-top border-bottom pt-3">
                  {transaction?.meta?.comment === '' || false ? null :
                    <Table responsive className="table-striped">
                      <tbody>
                      <tr>
                        <td className="fw-semibold font-italic text-gray-800 fw-bold">{t("Comment")}:</td>
                        <td className="text-gray-800 fw-bold">{transaction?.meta?.comment}</td>
                      </tr>
                      </tbody>
                    </Table>
                  }
                </div>
              ) : null}
            </Col>

            {/*<div className="d-flex justify-content-end">*/}
            {/*	<Button color="primary" className="btn btn-sm" onClick={closeModal}>*/}
            {/*		Close*/}
            {/*	</Button>*/}
            {/*</div>*/}

          </Row>
        ) : (
          <div className="d-flex flex-column align-items-center m-4">
            <span className="d-flex align-items-center">
              <Spinner size="sm" className="flex-shrink-0"></Spinner>
              <span className="flex-grow-1 ms-2 fs-16">{t("Loading")}...</span>
            </span>
          </div>
        )
      }
    </React.Fragment>
  )
}

export default TransactionsDetailsModal
