import React, {useState} from 'react'
import {Toolbar} from "@metronic/_metronic/layout/components/toolbar/Toolbar"
import {Content} from "@metronic/_metronic/layout/components/Content"
import {Link} from "@inertiajs/react"
import P2POrdersTable from "@metronic/Components/P2P/Components/P2POrdersTable.jsx"
import {Card, CardBody, Col, Row} from "reactstrap"
import {P2POrderPrint} from "@metronic/Components/P2P/Components"

const PeerToPeerContent = () => {
  const [order, setOrder] = useState(null)

  const handleOrderSelect = (selectedOrder) => {
    setOrder(selectedOrder)
  }

  return (
    <>
      <Toolbar/>
      <Content>
        <div  className='d-none d-print-block'>
          <P2POrderPrint order={order} />
        </div>

          <Row className="g-5 g-xl-10 mb-5 mb-xl-10 d-print-none">
           <Col>
             <Card>
               <CardBody>
                 <div className="mb-5 w-100 text-end">
                   <Link
                     id="create_p2p_order"
                     type="button"
                     color="primary"
                     href={route('p2p-orders.create-order')}
                     className="btn btn-primary fw-bold min-w-150px"
                   >
                     Create P2P Order
                   </Link>
                 </div>
                 <P2POrdersTable handleOrderSelect={handleOrderSelect}/>
               </CardBody>
             </Card>
           </Col>
          </Row>
      </Content>
    </>
  )
}

export default PeerToPeerContent
