import React, {useEffect, useState, createContext} from 'react'
import {Card, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap'
import classnames from 'classnames'
import WithdrawalCryptoForm from "@metronic/Components/Withdrawal/Crypto/WithdrawalCryptoForm"
import {useTranslation} from "react-i18next"

const WithdrawalCryptoCard = ({ formSubmit }) => {
  const [activeTab, setActiveTab] = useState('1')
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576)
  const { t } = useTranslation()

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const formSent = () => {
    if (formSubmit) {
      formSubmit()
    }
  }

  useEffect(() => {
    const resize = () => {
      setIsMobile(window.innerWidth <= 576)
    }
    window.addEventListener('resize', resize)

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex flex-column flex-sm-row">
          <h4 className={`card-title mb-0 flex-grow-1 ${isMobile ? 'mb-3' : ''}`}>{t("Withdraw Crypto")}</h4>
          <div className="d-flex justify-content-end">
            <Nav className="nav justify-content-end mb-0 nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
              <NavItem className='nav-item'>
                <NavLink
                  id="withdrawal_crypto_locked_tab"
                  href="#"
                  className={classnames('fs-5 me-5 px-1 border-bottom', {
                    'active text-primary border-primary': activeTab === '1',
                    'text-gray-600': activeTab !== '1'
                  })}
                  onClick={() => {toggleTab('1')}}
                >
                  {t("Locked Balance")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  id="withdrawal_crypto_available_tab"
                  href="#"
                  className={classnames('fs-5 px-1 border-bottom', {
                    'active text-primary border-primary': activeTab === '2',
                    'text-gray-600': activeTab !== '2'
                  })}
                  onClick={() => {toggleTab('2')}}
                >
                  {t("Available Balance")}
                </NavLink>
              </NavItem>
            </Nav>
          </div>

        </CardHeader>
        <div className="card-body">
          <TabContent activeTab={activeTab} className="p-0">
            <TabPane tabId="1">
              <WithdrawalCryptoForm formSentHandler={formSent} globalActiveTab={activeTab} />
            </TabPane>
            <TabPane tabId="2">
              <WithdrawalCryptoForm formSentHandler={formSent} globalActiveTab={activeTab} isReal={1}/>
            </TabPane>
          </TabContent>
        </div>
      </Card>
    </React.Fragment>
  )
}

export default WithdrawalCryptoCard
