import {toast} from "react-toastify"
import moment from "moment-timezone"
import React, {useEffect, useState} from "react"
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap"
import {useTranslation} from "react-i18next"
import classnames from "classnames"
import {Tooltip} from "react-tooltip"
import {capitalize, toLower} from "lodash"
import {router} from "@inertiajs/react"
import {TransactionStatus} from "@metronic/Enums/Transactions/TransactionStatus.js"
import {P2POrderStatusName} from "@metronic/Enums/P2POrders/P2POrderStatus.js"
import {TransferType} from "@metronic/Enums/Transfers/TransferType.js"
import {AppVariant} from "@metronic/Enums/AppVariants/AppVariant.js"

// Import Crypto-SVG
import btc from "../assets/images/svg/crypto-icons/btc.svg"
import eth from "../assets/images/svg/crypto-icons/eth.svg"
import ltc from "../assets/images/svg/crypto-icons/ltc.svg"
import dash from "../assets/images/svg/crypto-icons/dash.svg"
import usdt from "../assets/images/svg/crypto-icons/usdt.svg"
import usd from "../assets/images/svg/crypto-icons/usd.svg"
import eur from "../assets/images/svg/crypto-icons/eur.svg"
import gbp from "../assets/images/svg/crypto-icons/gbp.svg"
import xrp from "../assets/images/svg/crypto-icons/xrp.svg"
import ada from "../assets/images/svg/crypto-icons/ada.svg"
import doge from "../assets/images/svg/crypto-icons/doge.svg"
import cdn from "../assets/images/svg/crypto-icons/cdn.svg"
import tusd from "../assets/images/svg/crypto-icons/tusd.svg"
import aud from "../assets/images/svg/crypto-icons/aud.png"
import vnd from "../assets/images/svg/crypto-icons/vnd.png"
import sol from "../assets/images/svg/crypto-icons/sol.svg"

export const VITE_APP_VARIANT = import.meta.env.VITE_APP_VARIANT ? import.meta.env.VITE_APP_VARIANT : AppVariant.DEFAULT
export const IS_LIGHT_THEME = VITE_APP_VARIANT === AppVariant.LIGHT

export function copyToClipboard(data) {
  if (typeof data !== 'string') data = ''
  navigator.clipboard.writeText(data)

  toast.success('Copied to clipboard!')

  // if (localStorage.getItem('I18N_LANGUAGE') === 'en') {
  //   toast.success('Copied to clipboard!')
  // } else {
  //   toast.success('Скопировано в буфер обмена!')
  // }
}

export function toDateFormat(date) {
  return moment(date).format('YYYY-MM-DD')
}

export const twoPrecisionAssets = [
  'USD',
  'EUR',
  'USDT',
  'USDT (ERC20)',
  'GBP',
  'CAD',
  'TUSD',
  'AUD',
  'VND'
]

export function toTwoPrecision(val) {
  return (Number(val) || 0).toFixed(2)
}

export function isTwoPrecision(assetCode, val) {
  let amount = parseFloat(val)

  if (twoPrecisionAssets.includes(String(assetCode).toUpperCase())) {
    amount = amount.toFixed(2)
  } else {
    amount = amount.toFixed(8).replace(/0+$/, "")
  }

  if (Number.isInteger(parseFloat(val))) {
    return Number(amount).toFixed(2)
  }

  if (!val) return '0.00'

  return amount
}

export const cryptoIconsMapper = {
  BTC: btc,
  ETH: eth,
  LTC: ltc,
  DASH: dash,
  USDT: usdt,
  "USDT (ERC20)": usdt,
  USD: usd,
  EUR: eur,
  GBP: gbp,
  XRP: xrp,
  ADA: ada,
  DOGE: doge,
  CAD: cdn,
  TUSD: tusd,
  AUD: aud,
  VND: vnd,
  SOL: sol
}

export const timeZone = import.meta.env.VITE_APP_TIMEZONE ? import.meta.env.VITE_APP_TIMEZONE : 'UTC'

export function validateFloatValue(val) {
  const dotInput = val.replace(/,/g, '.')

  return /^\d*\.?\d*$/.test(dotInput) ? dotInput : false;
}

export function prettyDate(date, withTime = true) {
  moment.locale(localStorage.getItem('I18N_LANGUAGE'))

  if (localStorage.getItem('I18N_LANGUAGE') === 'ru') {
    moment.updateLocale('ru', {
      monthsShort: [
        "Янв", "Февр", "Мар", "Апр", "Мая", "Июня",
        "Июля", "Авг", "Сент", "Окт", "Нояб", "Дек"
      ]
    })
  }

  date = moment.tz(date, 'UTC').format()

  return (
    <span className="text-nowrap">
			<span>{moment.tz(date, timeZone).format(`DD MMM, yyyy`)}</span>
      {withTime && (
        <span className="ms-1 text-muted fs-12">{moment.tz(date, timeZone).format('HH:mm:ss')}</span>
      )}
		</span>
  )
}

export function printTransactionType(serverType) {
  switch (toLower(serverType)) {
    case TransferType.ACQUISITION: {
      return 'Deposit'
    }
    case TransferType.DEDUCTION: {
      return 'Withdrawal'
    }
    case 'invoice': {
      return 'Invoice'
    }
    default: {
      return serverType
    }
  }
}

export function copyChatMessage({text, title, type}) {
  return (
    <UncontrolledDropdown className="align-self-start message-box-drop">
      <DropdownToggle
        href="#"
        className="btn nav-btn p-2"
        tag="a"
        id={`${type}_dot_dropdown`}
      >
        <i className="bi bi-three-dots-vertical"></i>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          id={`${type}_dot_dropdown_item_copy`}
          href="#"
          onClick={() => copyToClipboard(text)}
          className="d-flex align-items-center"
        >
          <i className="bi bi-copy me-2 text-muted align-bottom"></i>
          <span>{title}</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export const TransactionBadgeIcon = ({transactionId, transactionType, invoiceId, invoiceAmount}) => {
  const {t} = useTranslation()

  const tooltipeId = String(transactionId)
  const tooltypeContent = String(capitalize(printTransactionType(invoiceId && Number(invoiceAmount) < 0 ? 'invoice' :
    transactionType)))

  return (
    <>
      <div
        className="h-25px w-25px rounded-circle d-flex justify-content-center align-items-center"
        data-tooltip-id={tooltipeId}
        data-tooltip-content={tooltypeContent}
      >
        <i className={classnames({
          'bi bi-arrow-up-right-circle text-danger fs-2': transactionType === TransferType.DEDUCTION && !invoiceId,
          'bi bi-arrow-down-left-circle text-success fs-2': transactionType === TransferType.ACQUISITION,
          'bi bi-arrow-repeat text-info fs-2': transactionType === TransferType.EXCHANGE && !invoiceId,
          'bi bi-file-earmark-medical text-warning fs-2': invoiceId && Number(invoiceAmount) < 0
        })}></i>
      </div>
      <Tooltip id={tooltipeId}/>
    </>
  )
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return windowSize
}

export function reloadData(data) {
  router.reload({only: ['flash', ...data]})
}

export const assetFullName = {
  BTC: 'Bitcoin',
  ETH: 'Ethereum',
  USDT: 'Tether',
  LTC: 'Litecoin',
  XRP: 'Ripple',
  DOGE: 'Dogecoin',
  ADA: 'Cardano',
  CAD: 'Canadian Dollar',
  EUR: 'Euro',
  GBP: "British Pound Sterling",
  USD: "United States Dollar",
  AUD: "Australian Dollar",
  VND: "Vietnamese Dong",
  SOL: "Solana"
}

export const checkImageValidity = async (url) => {
  return new Promise((resolve) => {
    const img = new Image()
    img.onload = () => resolve(true)
    img.onerror = () => resolve(false)
    img.src = url
  })
}

export const TransactionStatusBadge = ({status, color}) => {
  const { t } = useTranslation()

  return (
    <span className={'badge badge-light-' + color}>
      {t(`${capitalize(status)}`)}
      {toLower(status) === TransactionStatus.PENDING ? (
        <i className="bi bi-clock fs-8 align-bottom ms-2"></i>
      ) : (toLower(status) === TransactionStatus.EXECUTED ? (
        <i className="bi bi-check2-circle align-bottom ms-2"></i>
      ) : (toLower(status) === TransactionStatus.DECLINED ? (
        <i className="bi bi-exclamation-circle fs-8 align-bottom ms-2"></i>
      ) : (status === P2POrderStatusName.VERIFICATION ? (
        <i className="bi bi-search fs-8 align-bottom ms-2"></i>
      ) : (status === P2POrderStatusName.WAITING_FOR_PAYMENT ? (
        <i className="bi bi-bell fs-8 align-bottom ms-2"></i>
      ) : ''
      ))))}
    </span>
  )
}

export const formatSecuritiesPrice = (code, price) => {
  let formatPrice = !isNaN(Number(price)) ? Number(price).toFixed(2) : '-'

  switch (code) {
    case 'CAD':
      return <span>C${formatPrice}</span>
    case 'EUR':
      return <span>€{formatPrice}</span>
    case 'GBP':
      return <span>£{formatPrice}</span>
    default:
      return <span>${formatPrice}</span>
  }
}

export const isStickyHeader = () => {
  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const stickyHeader = document.body.getAttribute('data-kt-sticky-header')
      setIsSticky(stickyHeader === 'on')
    })

    observer.observe(document.body, { attributes: true })

    return () => observer.disconnect()
  }, [])

  return isSticky
}


