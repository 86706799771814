import React from 'react'
import {Link, router} from '@inertiajs/react'
import clsx from 'clsx'
import {KTIcon} from '../../../helpers'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
  slug?: string
  currentPage?: string
  asidePlanner?: boolean
  id?: string
}

const MenuItem: React.FC<Props> = ({
  to,
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
  slug= '',
  currentPage,
  asidePlanner = false,
  id
}) => {
  // const {pathname} = useLocation()

  return (
    <div className='menu-item me-lg-1'>
      <Link
        className={clsx('menu-link py-3 px-2 px-xl-4', asidePlanner && 'fs-5 fw-bold', {
          active: String(currentPage) === String(title),
        })}
        href={route(to, slug && {slug})}
        onClick={() => {
          if (slug) {
            sessionStorage.setItem('page_data', JSON.stringify({title: title, slug: slug}))
          }
        }}
        id={id}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}

        {icon && (
          <span className='menu-icon'>
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}

        {fontIcon && (
          <span className='menu-icon'>
            <i className={clsx('bi fs-3', fontIcon)}></i>
          </span>
        )}

        <span className='menu-title'>{title}</span>

        {hasArrow && <span className='menu-arrow'></span>}
      </Link>
    </div>
  )
}

export {MenuItem}
