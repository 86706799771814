import React, {useEffect, useState} from 'react'
import {Toolbar} from "@metronic/_metronic/layout/components/toolbar/Toolbar"
import {Content} from "@metronic/_metronic/layout/components/Content"
import {Col, Row, Button, Card, CardBody, DropdownToggle, Dropdown, DropdownMenu} from "reactstrap"
import {KTIcon} from "@metronic/_metronic/helpers"
import {IS_LIGHT_THEME, prettyDate, toDateFormat} from "@metronic/helpers/utils"
import {Link, router, usePage} from "@inertiajs/react"
import moment from "moment-timezone"
import {clsx} from "clsx"
import {useTranslation} from "react-i18next"
import {InvoiceStatus} from "@metronic/Enums/Invoices/InvoiceStatus.js"

const InvoiceDetails = () => {
  const {props: pageProps} = usePage()
  const [brand, setBrand] = useState('')
  const [invoiceData, setInvoiceData] = useState({})
  const [termsOfDays, setTermsOfDays] = useState(0)
  const [userData, setUserData] = useState({})
  const [isDropdown, setIsDropdown] = useState(false)
  const { t } = useTranslation()

  const printInvoice = () => {
    window.print()
  }

  const getInvoiceLogo = () => {
    if (brand) {
      return import.meta.env.VITE_REMOTE_STORAGE + brand?.logo_path
    } else if (invoiceData.mail_from === 'custom') {
      return import.meta.env.VITE_REMOTE_STORAGE + invoiceData.meta.custom_from.logo
    }
  }

  const cryptoPayHandler = (id) => {
    if (confirm(t('Do you want to pay by crypto?'))) {
      router.post('/invoices/crypto-pay', {
        id: id
      })
    }
  }

  const balancePayHandler = (id) => {
    if (confirm(t('Do you want to pay from your balance?'))) {
      router.post('/invoices/balance-pay', {
        id: id
      })
    }
  }

  const toggleDropdown = () => {
    setIsDropdown(!isDropdown)
  }

  useEffect(() => {
    setInvoiceData(pageProps.invoice)
    setUserData(pageProps.auth.user)
  }, [pageProps])

  useEffect(() => {
    setBrand(invoiceData.brand)

    const dueDate = toDateFormat(invoiceData.due_date)
    const invoiceDate = toDateFormat(invoiceData.invoicing_date)
    const dueDateFormatted = moment(dueDate, 'YYYY-MM-DD');
    const invoiceDateFormatted = moment(invoiceDate, 'YYYY-MM-DD');
    const termsDays = dueDateFormatted.diff(invoiceDateFormatted, 'days')

    setTermsOfDays(termsDays)
  }, [invoiceData])

  return (
    <>
      <Toolbar />
      <Content>
        <Row className="g-5 g-xl-10 mb-5 mb-xl-10 d-flex justify-content-center">
          <Col className={clsx('',
            IS_LIGHT_THEME ? 'col-12 col-md-10 col-lg-12 col-xl-10 col-xxl-8' : 'col-12 col-md-10 col-lg-8'
            )}>
            <Card>
              <CardBody className="card-body px-20 py-10">
                <div className="d-flex flex-column flex-xl-row">
                  <div className="flex-lg-row-fluid mb-10 mb-xl-0">
                    <div className="mt-n1">

                      <div className={clsx('d-flex flex-column flex-sm-row flex-sm-row-reverse flex-stack pb-10',
                        IS_LIGHT_THEME ? 'flex-lg-column flex-xl-row-reverse' : '')}>
                        {invoiceData.status !== InvoiceStatus.PAID &&
                          <div className={clsx('mb-3 mb-sm-0 d-flex d-print-none justify-content-center' +
                            ' pb-5 pb-sm-0 justify-content-sm-end w-100',
                            IS_LIGHT_THEME ? 'justify-content-lg-center justify-content-xl-end mb-lg-3 mb-xl-0' : ''
                          )}>
                            <Dropdown
                              isOpen={isDropdown}
                              toggle={toggleDropdown}
                            >
                              <DropdownToggle
                                id="invoice_payment_dropdown"
                                type="button"
                                tag="button"
                                color="success"
                                className="btn btn-sm btn-success fw-bold"
                              >
                                Pay now <i className={`bi bi-chevron-${!isDropdown ? 'down' : 'up'}`}></i>
                                {/*<Button type="button" className="btn btn-sm btn-success"></Button>*/}
                              </DropdownToggle>
                              <DropdownMenu
                                end
                                className="dropdown-menu-lg py-0"
                                style={{background: 'black !important'}}
                              >
                                <div className="d-flex flex-column">
                                  <Button
                                    id="invoice_payment_dropdown_top_up"
                                    className="btn btn-sm btn-rimary text-nowrap fw-bold"
                                    color="primary"
                                    type="button"
                                    onClick={() => cryptoPayHandler(invoiceData.id)}
                                  >
                                    Top up
                                  </Button>
                                  <Button
                                    id="invoice_payment_dropdown_from_balance"
                                    type="button"
                                    className="btn btn-sm btn-success text-nowrap fw-bold"
                                    onClick={() => balancePayHandler(invoiceData.id)}
                                  >
                                    Pay from balance
                                  </Button>
                                </div>
                              </DropdownMenu>
                            </Dropdown>
                            {/*</div>*/}
                          </div>
                        }
                        <div className={clsx('d-flex w-100 justify-content-center justify-content-sm-start',
                          IS_LIGHT_THEME ? 'justify-content-lg-center justify-content-xl-start' : ''
                        )}>
                          {invoiceData &&
                            <img
                              alt="brand_logo"
                              src={getInvoiceLogo()}
                              className="w-200px"
                            />
                          }
                        </div>
                      </div>

                      <div className="m-0">
                        <div className="fw-bold fs-3 text-gray-800 mb-8">Invoice {invoiceData.uniq_id}</div>

                        <Row className="g-5 mb-5 mb-sm-11">
                          <Col className="col-6">
                            <div className="fw-bold fs-7 text-gray-600 mb-1">Invoicing date:</div>
                            <div className="fw-bold fs-6 text-gray-800">{prettyDate(invoiceData.invoicing_date, false)}</div>
                          </Col>
                          <Col className="col-6">
                            <div className="fw-bold fs-7 text-gray-600 mb-1">Due date:</div>
                            <div className="fw-bold fs-6 text-gray-800 d-flex align-items-center flex-wrap">
                              <span className="pe-2">{prettyDate(invoiceData.due_date, false)}</span>
                              <span className="fs-7 text-warning d-flex align-items-center">
																<span className="bullet bullet-dot bg-warning me-2"></span>
                                Terms: {termsOfDays === 1 ? `${termsOfDays} day` : `${termsOfDays} days`}
                              </span>
                            </div>
                          </Col>
                        </Row>

                        <Row className={clsx('g-5 mb-12 d-flex flex-column-reverse flex-sm-row',
                          IS_LIGHT_THEME ? 'flex-lg-column-reverse flex-xl-row' : ''
                          )}>
                          <Col className={clsx('col-12 col-sm-6',
                            IS_LIGHT_THEME ? 'col-lg-12 col-xl-6' : ''
                          )}>
                            <div className="fw-bold fs-7 text-gray-600 mb-1">Bill to:</div>
                            <div className="fw-bold fs-6 text-gray-800">{userData.name && userData.name}</div>
                            {userData?.profile?.address &&
                              <span className="fw-bold fs-7 text-gray-600">{userData?.profile?.address}</span>
                            }
                              {userData?.profile?.state && <span className="fw-bold fs-7 text-gray-600">, {userData?.profile?.state}</span>}
                            {userData?.profile?.country && <span className="fw-bold fs-7 text-gray-600">, {userData?.profile?.country}</span>}
                            {userData?.profile?.postal_code && <span className="fw-bold fs-7 text-gray-600">, {userData?.profile?.postal_code}</span>}
                          </Col>
                          <Col className={clsx('col-12 col-sm-6',
                            IS_LIGHT_THEME ? 'col-lg-12 col-xl-6' : ''
                          )}>
                            <div className="fw-bold fs-7 text-gray-600 mb-1">From:</div>
                            {invoiceData.mail_from === 'custom' ?
                              <>
                                <div className="fw-bold fs-6 text-gray-800">
                                  {invoiceData.meta.custom_from.custom_name}
                                </div>
                                <div className="fw-bold fs-7 text-gray-600">
                                  {invoiceData.meta.custom_from.custom_address &&
                                    invoiceData.meta.custom_from.custom_address.split(/\r?\n/)
                                      .map((string, idx) => (
                                        <React.Fragment key={idx}>
                                          <span>{string}</span> <br/>
                                        </React.Fragment>
                                      ))
                                  }
                                </div>
                                <p className="fs-7 fw-bold text-muted">
                                  {invoiceData.meta.custom_from.custom_info}
                                </p>
                              </> :
                              <>
                                <div className="fw-bold fs-6 text-gray-800">{brand?.title}</div>
                                <div className="fw-bold fs-7 text-gray-600">
                                  {brand?.address && brand?.address.split(/\r?\n/)
                                    .map((string, idx) => (
                                      <React.Fragment key={idx}>
                                        <span>{string}</span> <br/>
                                      </React.Fragment>
                                    ))}
                                </div>
                              </>
                            }
                          </Col>
                        </Row>

                        <div className="flex-grow-1">
                          <div className="table-responsive border-bottom mb-9">
                          <table className="table mb-3">
                              <thead>
                              <tr className="border-bottom bg-light fs-6 fw-bold">
                                <th className="min-w-100px pb-2">ITEM</th>
                                <th className="min-w-70px text-end pb-2">QUANTITY</th>
                                <th className="min-w-80px text-end pb-2">RATE</th>
                                <th className="min-w-100px text-end pb-2">AMOUNT</th>
                              </tr>
                              </thead>
                              <tbody>
                                {(invoiceData && invoiceData.items) ? invoiceData.items.map((item, idx) => (
                                    <tr key={idx} className="fw-bold text-gray-700 fs-5 text-end">
                                      <td className="d-flex align-items-center pt-6">
                                        {item.product_description}
                                      </td>
                                      <td className="pt-6">{item.quantity}</td>
                                      <td className="pt-6 text-nowrap">{Number(item.rate).toFixed(2)} {invoiceData.formatted_code}</td>
                                      <td className="pt-6 text-gray-900 fw-bolder text-nowrap">
                                        {Number(item.amount).toFixed(2)} {invoiceData.formatted_code}
                                      </td>
                                    </tr>
                                  )) : null
                                }
                              </tbody>
                            </table>
                          </div>

                          <Row className="d-flex justify-content-end">
                            <Col className={clsx('col-12 col-sm-5',
                              IS_LIGHT_THEME ? 'col-lg-12 col-xl-7' : ''
                              )}>
                              <div className="d-flex flex-stack mb-3">
                                <div className="fw-bold pe-10 text-gray-800 fs-7 text-nowrap">Subtotal:</div>
                                <div className="text-end fw-bold fs-6 text-gray-800">
                                  {Number(invoiceData.subtotal_amount).toFixed(2)} {invoiceData.formatted_code}
                                </div>
                              </div>
                              <div className="d-flex flex-stack mb-3">
                                <div className="fw-bold pe-10 text-gray-600 fs-7">Discount:</div>
                                <div className="text-end fw-bold fs-6 text-gray-700">
                                  {invoiceData.discount ? Number(invoiceData.discount).toFixed(2) : '0.00'}%
                                </div>
                              </div>
                              <div className="d-flex flex-stack mb-3">
                                <div className="fw-bold pe-10 text-gray-600 fs-7">Tax:</div>
                                <div className="text-end fw-bold fs-6 text-gray-700">
                                  {invoiceData.tax ? Number(invoiceData.tax).toFixed(2) : '0.00'}%
                                </div>
                              </div>
                              <div className="d-flex flex-stack">
                                <div className="fw-bold pe-10 fw-semibold text-gray-800 fs-7">Total</div>
                                <div className="text-end fw-bold fs-6 text-gray-800 text-nowrap">
                                  {Number(invoiceData.total_amount).toFixed(2)} {invoiceData.formatted_code}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*<div className="m-0">*/}
                  {/*  <div*/}
                  {/*    className="d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten">*/}
                  {/*    <div className="mb-8">*/}
                  {/*      <span className="badge badge-light-success me-2">Approved</span>*/}
                  {/*      <span className="badge badge-light-warning">Pending Payment</span>*/}
                  {/*    </div>*/}

                  {/*    <h6 className="mb-8 fw-bolder text-gray-600 text-hover-primary">PAYMENT DETAILS</h6>*/}

                  {/*    <div className="mb-6">*/}
                  {/*      <div className="fw-semibold text-gray-600 fs-7">Paypal:</div>*/}
                  {/*      <div className="fw-bold text-gray-800 fs-6">codelabpay@codelab.co</div>*/}
                  {/*    </div>*/}

                  {/*    <div className="mb-6">*/}
                  {/*      <div className="fw-semibold text-gray-600 fs-7">Account:</div>*/}
                  {/*      <div className="fw-bold text-gray-800 fs-6">Nl24IBAN34553477847370033*/}
                  {/*        <br/>AMB NLANBZTC*/}
                  {/*      </div>*/}
                  {/*    </div>*/}

                  {/*    <div className="mb-15">*/}
                  {/*      <div className="fw-semibold text-gray-600 fs-7">Payment Term:</div>*/}
                  {/*      <div className="fw-bold fs-6 text-gray-800 d-flex align-items-center">14 days*/}
                  {/*        <span className="fs-7 text-danger d-flex align-items-center">*/}
									{/*				<span className="bullet bullet-dot bg-danger mx-2"></span>Due in 7 days</span></div>*/}
                  {/*    </div>*/}

                  {/*    <h6 className="mb-8 fw-bolder text-gray-600 text-hover-primary">PROJECT OVERVIEW</h6>*/}

                  {/*    <div className="mb-6">*/}
                  {/*      <div className="fw-semibold text-gray-600 fs-7">Project Name</div>*/}
                  {/*      <div className="fw-bold fs-6 text-gray-800">SaaS App Quickstarter*/}
                  {/*        <a href="#" className="link-primary ps-1">View Project</a></div>*/}
                  {/*    </div>*/}

                  {/*    <div className="mb-6">*/}
                  {/*      <div className="fw-semibold text-gray-600 fs-7">Completed By:</div>*/}
                  {/*      <div className="fw-bold text-gray-800 fs-6">Mr. Dewonte Paul</div>*/}
                  {/*    </div>*/}

                  {/*    <div className="m-0">*/}
                  {/*      <div className="fw-semibold text-gray-600 fs-7">Time Spent:</div>*/}
                  {/*      <div className="fw-bold fs-6 text-gray-800 d-flex align-items-center">230 Hours*/}
                  {/*        <span className="fs-7 text-success d-flex align-items-center">*/}
									{/*				<span className="bullet bullet-dot bg-success mx-2"></span>35$/h Rate</span></div>*/}
                  {/*    </div>*/}

                  {/*  </div>*/}

                  {/*</div>*/}

                </div>

                <div className="pt-10 d-print-none d-flex justify-content-between">
                  <Link
                    id="invoice_back_button"
                    as='button'
                    type="button"
                    href={route('invoices.index')}
                    className="btn btn-sm btn-primary px-2 px-sm-5 w-80px w-sm-100px fw-bold"
                    color='primary'
                  >
                    Back
                  </Link>
                  <Button
                    id="invoice_print_button"
                    type="button"
                    onClick={printInvoice}
                    className="btn btn-sm btn-success px-2 px-sm-5 w-80px w-sm-100px fw-bold"
                  >
                    Print <KTIcon iconName='printer' className='fs-2 ms-2'/>
                  </Button>
                </div>
              </CardBody>

            </Card>
          </Col>
        </Row>
      </Content>
    </>
  )
}

export default InvoiceDetails
