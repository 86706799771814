import React, {FC} from 'react'
import clsx from 'clsx'
import {HeaderUserMenu} from '../../../../partials'
import {NotificationsDropdown} from '@metronic/Components/HeaderDropdowns'
import {usePage} from "@inertiajs/react"
import {KTIcon} from "../../../../helpers"
import {Tooltip} from "react-tooltip"
import {capitalize} from "lodash"
import {useThemeMode} from "../../../../partials/layout/theme-mode/ThemeModeProvider"
import {isStickyHeader} from '@metronic/helpers/utils'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40p',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'fs-1'

const TopbarDefault: FC = () => {
  const {props: pageProps} = usePage()
  const notificationsData = pageProps?.auth?.notifications?.data
  const verificationStatus = usePage().props?.auth?.user?.profile?.verification_status
  const {mode} = useThemeMode()
  const isSticky = isStickyHeader()

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        <div className={clsx('d-flex align-items-center')}>
          <div className={clsx('btn btn-icon btn-circle position-relative')}>
              <span
                id="verification_icon"
                data-tooltip-id={'ver_aside'}
                data-tooltip-content={capitalize(verificationStatus)}
              >
                <KTIcon iconName='verify' className={clsx('fs-2x',
                  verificationStatus === 'verified' ? 'text-success' :
                    (mode === 'light' || mode === 'system') ?
                      (isSticky ? '' : 'text-gray-100') : 'text-body'
                )}/>
                <Tooltip id={'ver_aside'}/>
              </span>
          </div>
        </div>

        {/* Notifications dropdown BEGIN */}
        <div className={clsx('d-flex align-items-center me-lg-0 me-1')}>
          <div className={clsx('btn btn-icon btn-active-light-primary btn-custom position-relative',
            toolbarButtonHeightClass
          )}
          >
            <NotificationsDropdown/>
            {(notificationsData?.length && notificationsData?.filter(i => !i.read_at)?.length) ?
              <span
                className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span> :
              null
            }

          </div>
        </div>
        {/* Notifications dropdown END */}

        {/* Theme mode dropdown BEGIN */}
        {/*<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>*/}
        {/*  <ThemeModeSwitcher toggleBtnClass={toolbarButtonHeightClass}/>*/}
        {/*</div>*/}
        {/* Theme mode END */}

        {/* User dropdown BEGIN */}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <div className={clsx('cursor-pointer symbol ms-1', toolbarUserAvatarHeightClass)}></div>
          <HeaderUserMenu/>
        </div>
        {/* User dropdown END */}

      </div>
    </div>
  )
}

export {TopbarDefault}
