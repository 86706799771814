import React, {FC, useState} from 'react'
import {toAbsoluteUrl} from '@metronic/_metronic/helpers'
import clsx from "clsx"
import {Tooltip} from "react-tooltip"
import { prettyDate } from '@metronic/helpers/utils'
import {capitalize} from "lodash"
import {DocumentStatus} from "@metronic/Enums/Documents/DocumentStatus.js"

type Props = {
    icon: string
    title: string
    createdDate: string
    status: string
    name: string
    index: number
}

const Card4: FC<Props> = ({icon, title, createdDate, status, name, index}) => {
    const [isMouseOnCard, setIsMouseOnCard] = useState(false)

    return (
        <div
            id={`document_card_${index + 1}`}
            className='card h-100 cursor-pointer position-relative'
            onMouseEnter={() => setIsMouseOnCard(!isMouseOnCard)}
            onMouseLeave={() => setIsMouseOnCard(!isMouseOnCard)}
        >
            <div
                className={clsx(
                    'card-body d-flex text-hover-primary justify-content-center text-center flex-column p-8',
                    isMouseOnCard && 'bg-light'
                )}
            >
          <span className='text-gray-800 d-flex flex-column'>
            <div className='symbol symbol-60px mb-3'>
              <img src={icon} alt=''/>
            </div>
            <div
              className={clsx('fs-5 fw-bold mb-2 text-truncate', isMouseOnCard && 'text-primary')}
              data-tooltip-id={createdDate}
              data-tooltip-content={`${title ? title : ''} ${title ? '(' : ''}${name}${title ? ')' : ''}`}
            >
              {title || name}
            </div>
            <Tooltip id={createdDate} />
          </span>
                <div
                    className={clsx('fs-7 mt-auto', isMouseOnCard ? 'text-gray-900 fw-bold' : 'text-gray-500 fw-bold')}>
                    {prettyDate(createdDate)}
                </div>
            </div>

            {/*<div*/}
            {/*    className='position-absolute d-flex justify-content-center align-items-centr translate-middle rounded-circle border border*/}
            {/*border-light-danger bg-light-danger align-items-center bg-light cursor-pointer mt-10 me-5 p-2'*/}
            {/*    style={{right: '-25px', top: '-5px'}}*/}
            {/*    data-tooltip-id={title}*/}
            {/*    data-tooltip-content={'Delete file'}*/}
            {/*>*/}
            {/*    <i className="bi bi-trash fs-2 text-danger bg-light-danger"></i>*/}
            {/*</div>*/}
            {/*<Tooltip id={title}/>*/}

            <div
                className={clsx(
                    'position-absolute d-flex justify-content-center ' +
                    'translate-middle rounded-circle border border\n' +
                    ' align-items-center bg-light mt-10 me-5 p-2',
                    status === DocumentStatus.DECLINED ? 'border-light-danger bg-light-danger' :
                        status === DocumentStatus.VERIFIED ? 'border-light-success bg-light-success' :
                            'border-light-primary bg-light-primary'
                )}
                style={{left: '25px', top: '-5px'}}
                data-tooltip-id={name}
                data-tooltip-content={capitalize(status)}
            >
                <i className={clsx('bi',
                    status === DocumentStatus.DECLINED ? 'bi-patch-exclamation fs-2 text-danger bg-light-danger' :
                        status === DocumentStatus.VERIFIED ? 'bi-patch-check fs-2 text-success bg-light-success' :
                            'bi-patch-question fs-2 text-primary bg-light-primary'
                )}>
                </i>
            </div>
            <Tooltip id={name}/>
        </div>
    )
}

export {Card4}
