import React, {useEffect, useState} from 'react'
import {Card, CardBody, Col, Row, Spinner, Table} from "reactstrap"
import {clsx} from "clsx"

// Components
import {Toolbar} from "@metronic/_metronic/layout/components/toolbar/Toolbar"
import {Content} from "@metronic/_metronic/layout/components/Content"
import {copyToClipboard} from "@metronic/helpers/utils"
import {Link, usePage} from "@inertiajs/react"

const BankDetailsContent = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [activeTab, setActiveTab] = useState('')
  const {props: pageProps} = usePage()
  const bankDetails = pageProps.bank_details

  useEffect(() => {
    if (bankDetails && bankDetails?.length) {
      setActiveTab(bankDetails[0].name)
    } else if (bankDetails && !bankDetails.length) {
      setIsLoading(false)
    }
  }, [bankDetails])

  return (
    <>
      <Toolbar/>
      <Content>
        <Row className="g-5 g-xl-10 mb-5 mb-xl-10 d-flex justify-content-center">
          <Col>
            <Card className="card-p-0 px-10 pb-10 pt-3">
              <div className="text-start mt-5 mb-5 fs-4 text-gray-700 fw-bold">Banks List</div>
              <CardBody>
                <div className="mb-5 hover-scroll-x">
                  <div className="d-grid">
                    <ul className='nav nav-tabs flex-nowrap text-nowrap'>
                      {bankDetails.length > 0 &&
                        bankDetails.map((bankDetail, idx) => (
                          <li key={idx} className="nav-item">
                            <Link
                              id={`bank_details_tab_${String(bankDetail.name).replace(' ', '_')}`}
                              onClick={() => setActiveTab(bankDetail.name)}
                              className={clsx(
                                'nav-link btn btn-active-secondary btn-color-gray-600 fs-6 fw-bold btn-active-color-primary rounded-bottom-0',
                                activeTab === bankDetail.name && 'active'
                              )}
                              data-bs-toggle="tab"
                              href={`#${bankDetail.name}`}
                            >
                              {bankDetail.name}
                            </Link>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>

                <div className="" id="myTabContent">
                {bankDetails.length > 0 ?
                  bankDetails.map((bankDetail, idx) => (
                    <div
                      className={clsx('tab-pane fade show active', activeTab !== bankDetail.name ? 'd-none' : '')}
                      role="tabpanel"
                      key={idx}
                      id={bankDetail.name}
                    >
                      <Row className="d-flex mt-3 d-flex">
                        <Col xs={12}>
                          <div
                            className="text-center mb-3 pb-3 pt-3 border-bottom
                                      border-bottom-dashed border-top border-top-dashed"
                          >
                            BANK INFORMATION
                          </div>
                          <Table borderless responsive className="text-nowrap">
                            <tbody>
                            <tr>
                              <td className="border-bottom border-bottom-dashed pb-4">
                                {bankDetail.fields.credentials.map((field, idx) =>
                                    <div
                                      className="fs-14 d-flex"
                                      key={idx}
                                    >
                                      <span className="fw-medium d-block w-25 ms-2 text-start me-2 min-w-125px">
                                        <i className="bi bi-check-circle text-primary me-1"></i>
                                        {field.key}:
                                      </span>
                                      <span
                                        id={`${String(activeTab).replace(' ', '_')}_field_value_${idx + 1}`}
                                        className="cursor-pointer text-gray-700 fw-bold"
                                        onClick={() => copyToClipboard(field.value)}
                                      >
                                       {field.value}
                                      </span>
                                    </div>
                                  )}
                                </td>
                              </tr>
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </div>
                    )) : (
                      isLoading ?
                        <div className="w-100 text-center">
                          <Spinner color="primary" className="my-3">Loading ...</Spinner>
                        </div> :
                        <div className="w-100 text-center pt-3 mb-3 border-bottom
                                border-bottom-dashed border-top border-top-dashed">
                          <p className="fs-18">Banks list is empty.</p>
                        </div>
                    )
                  }
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Content>
    </>
  )
}

export default BankDetailsContent
