import React, {useEffect, useState} from 'react'
import {Link, router, useForm, usePage} from '@inertiajs/react'
import {KTIcon, toAbsoluteUrl} from "@metronic/_metronic/helpers"
import {Tooltip} from "react-tooltip"
import {clsx} from "clsx"
import {useTabContext} from "@metronic/Components/Profile/TabContext/TabContext"
import {Input} from "reactstrap"
import defaultAvatar from '@metronic/assets/images/users/user-dummy-img.jpg'
import {capitalize} from "lodash"

const ProfileHeader = () => {
  const { activeTab, setActiveTab } = useTabContext()
  const {props: pageProps} = usePage()
  const profileData = pageProps.profile_data
  const accountBalance = pageProps.account_balance

  const location = 'useLocation()'

  const {data, setData, post, reset} = useForm({
    avatar_img: '',
  })

  const changeAvatarFormHandler = (file) => {
    router.post('/profile/change-avatar', {
      avatar_img: file
    })
  }

  useEffect(() => {
    setActiveTab('overview')
  }, [])

  return (
    <>
      <div className='card'>
        <div className='card-body pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative border border-success'>
                <img className="object-fit-cover" src={profileData?.avatar_url ? profileData?.avatar_url : defaultAvatar} alt='Photo'/>
                <div
                  className='position-absolute d-flex justify-content-center translate-middle start-100 top-0
                    rounded-circle border border-4 border-light align-items-center bg-light cursor-pointer'
                >
                  <i className="bi bi-camera fs-3 text-success position-relative"></i>
                  <Input
                    name="avatar_img"
                    id="profile-img-file-input"
                    type="file"
                    className="profile-img-file-input position-absolute opacity-0 h-30px w-30px"
                    onChange={(e) => {
                      changeAvatarFormHandler(e.target.files[0])
                    }}
                  />
                </div>
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-2'>
                      {profileData.first_name} {profileData?.last_name}
                    </a>
                    {/*<span*/}
                    {/*  data-tooltip-id={'ver_prof'}*/}
                    {/*  data-tooltip-content={capitalize(profileData?.verification_status)}*/}
                    {/*>*/}
                    {/*  <KTIcon iconName='verify' className={clsx('fs-1',*/}
                    {/*    profileData?.verification_status === 'verified' ?  'text-success' : 'text-muted'*/}
                    {/*    )}/>*/}
                    {/*  <Tooltip id={'ver_prof'} />*/}
                    {/*</span>*/}
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    {/*<a*/}
                    {/*  href='#'*/}
                    {/*  className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2'*/}
                    {/*>*/}
                    {/*  <KTIcon iconName='profile-circle' className='fs-4 me-1'/>*/}
                    {/*  Developer*/}
                    {/*</a>*/}
                    <span className='d-flex align-items-center text-gray-600 me-5 mb-2'>
                      <KTIcon iconName='geolocation' className='fs-4 me-1'/>
                      {profileData.customer_country}, {profileData.city}
                    </span>
                    <span className='d-flex align-items-center text-gray-600 mb-2'>
                      <KTIcon iconName='sms' className='fs-4 me-1'/>
                      {profileData.email}
                    </span>
                  </div>
                </div>

                {/*<div className='d-flex my-4'>*/}
                {/*  <a href='#' className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>*/}
                {/*    <KTIcon iconName='check' className='fs-3 d-none'/>*/}

                {/*    <span className='indicator-label'>Follow</span>*/}
                {/*    <span className='indicator-progress'>*/}
                {/*      Please wait...*/}
                {/*      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>*/}
                {/*    </span>*/}
                {/*  </a>*/}
                {/*  <a*/}
                {/*    href='#'*/}
                {/*    className='btn btn-sm btn-primary me-3'*/}
                {/*    data-bs-toggle='modal'*/}
                {/*    data-bs-target='#kt_modal_offer_a_deal'*/}
                {/*  >*/}
                {/*    Hire Me*/}
                {/*  </a>*/}
                {/*  <div className='me-0'>*/}
                {/*    <button*/}
                {/*      className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'*/}
                {/*      data-kt-menu-trigger='click'*/}
                {/*      data-kt-menu-placement='bottom-end'*/}
                {/*      data-kt-menu-flip='top-end'*/}
                {/*    >*/}
                {/*      <i className='bi bi-three-dots fs-3'></i>*/}
                {/*    </button>*/}
                {/*    /!*<Dropdown1 />*!/*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/*<KTIcon iconName='arrow-up' className='fs-3 text-success me-2'/>*/}
                        <div className='fs-2 fw-bolder'>
                          ${accountBalance?.total ? Intl.NumberFormat().format(accountBalance?.total) : '0.00'}
                        </div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-800'>Account Balance</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/*<KTIcon iconName='arrow-down' className='fs-3 text-danger me-2'/>*/}
                        <div className='fs-2 fw-bolder'>
                          ${accountBalance?.available ? Intl.NumberFormat().format(accountBalance?.available) : '0.00'}
                        </div>
                      </div>

                      <div className='fw-bold fs-6 text-success'>Available</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/*<KTIcon iconName='arrow-up' className='fs-3 text-success me-2'/>*/}
                        <div className='fs-2 fw-bolder'>
                          ${accountBalance?.locked ? Intl.NumberFormat().format(accountBalance?.locked) : '0.00'}
                        </div>
                      </div>

                      <div className='fw-bold fs-6 text-danger'>Locked</div>
                    </div>
                  </div>
                </div>

                <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                  <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                    <span className='fw-bold fs-6 text-gray-500'>Your Profile Progress</span>
                    <span className='fw-bolder fs-6'>{profileData?.progress_pct}%</span>
                  </div>
                  <div className='h-5px mx-3 w-100 bg-light mb-3'>
                    <div
                      className='bg-success rounded h-5px'
                      role='progressbar'
                      style={{width: `${profileData?.progress_pct}%`}}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul
              className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  id="profile_nav_tab_overview"
                  onClick={() => setActiveTab('overview')}
                  className={clsx('nav-link text-active-primary me-6', activeTab === 'overview' && 'active')}
                  data-bs-toggle="tab"
                  href='#overview'
                >
                  Overview
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  id="profile_nav_tab_personal_info"
                  onClick={() => setActiveTab('personal_information')}
                  className={clsx('nav-link text-active-primary me-6', activeTab === 'settings' && 'active')}
                  data-bs-toggle="tab"
                  href='#personal_information'
                >
                  Personal information
                </Link>
              </li>
              <li className='nav-item text-nowrap'>
                <Link
                  id="profile_nav_tab_change_pass"
                  onClick={() => setActiveTab('change_password')}
                  className={clsx('nav-link text-active-primary me-6', activeTab === 'change_password' && 'active')}
                  data-bs-toggle="tab"
                  href='#change_password'
                >
                  Change Password
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  id="profile_nav_tab_documents"
                  onClick={() => setActiveTab('documents')}
                  className={clsx('nav-link text-active-primary me-6', activeTab === 'documents' && 'active')}
                  data-bs-toggle="tab"
                  href='#documents'
                >
                  Documents
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileHeader
