import React, {useEffect, useRef, useState} from 'react'
import clsx from "clsx"
import {copyChatMessage, prettyDate} from "@metronic/helpers/utils.jsx"
import defaultAvatar from "@metronic/assets/images/users/user-dummy-img.jpg"
import {usePage} from "@inertiajs/react"
import moment from "moment-timezone"
import _ from "lodash"

const ChatBodyScrollBar = (props) => {
  const {
    chat = false,
    data,
    initialMessage,
    isChatOpen = false
  } = props
  const [messageId, setMessageId] = useState('')
  const [currentAvatar, setCurrentAvatar] = useState(defaultAvatar)
  const [messages, setMessages] = useState([])
  const messagesEndRef = useRef(null)
  const {props: pageProps} = usePage()
  const userId = pageProps?.auth?.user?.id
  const currentUser = pageProps?.auth?.user
  const isChatForId = chat ? 'chat' : 'support'

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const isMessageFromSameUser = (arr) => {
    setMessages(arr.reduce((acc, item, idx) => {
      let newItem = {...item}

      if (idx > 0 && item.user_id === arr[idx - 1].user_id) {
        newItem.isUserSame = true
      }

      if (idx === arr.length - 1 || item.user_id !== arr[idx + 1].user_id) {
        newItem.isUserEnd = true
      }

      acc.push(newItem)

      return acc
    }, []))
  }


  useEffect(() => {
    if (currentUser.profile?.avatar_url) {
      setCurrentAvatar(currentUser.profile.avatar_url)
    } else {
      setCurrentAvatar(defaultAvatar)
    }
  }, [currentUser])

  useEffect(() => {
    if (data && data.length) {
      isMessageFromSameUser(data)
    }
  }, [data])

  useEffect(() => {
    scrollToBottom()
  }, [messages, isChatOpen])

  return (
    <>
      {!_.isEmpty(messages) ? messages.map((message, index) => {
        const state = userId !== message?.user_id ? 'primary' : 'success'
        const contentClass =
          `justify-content-${userId !== message?.user_id ? 'start' : 'end'} mb-1`
        const templateAttr = {}
        const isInMessage = userId !== message?.user_id

        if (message?.message) {
          Object.defineProperty(templateAttr, 'data-kt-element', {
            value: `template-${isInMessage ? 'in' : 'out'}`,
          })
        }

        return (
          <div
            key={index}
            onMouseEnter={() => setMessageId(String(index))}
            onMouseLeave={() => setMessageId('')}
            className={clsx(
              'd-flex',
              contentClass,
              Number(index) === 0 ? 'flex-column' : '',
              Number(index) === Number(messages?.length - 1) ? 'mb-10' : ''
            )}
            {...templateAttr}
          >
            {Number(index) === 0 ?
              <div className='w-100 text-center text-gray-700 fs-5 fw-bold pb-5'>
                Open {prettyDate(message.created_at)}
              </div> : null
            }
            <div
              className={clsx(
                'd-flex flex-column',
                `align-items-${isInMessage ? 'start' : 'end'}`,
                ((Number(index) === Number(messages.length - 1)) && (Number(initialMessage?.status) === 4)) && 'w-100'
              )}
            >
              <div className={clsx('d-flex align-items-center mb-2', message?.isUserSame ? 'd-none' : '',)}>
                {isInMessage ? (
                  <>
                    <div className='symbol symbol-35px symbol-circle '>
                      <img alt='Pic' src={defaultAvatar}/>
                    </div>
                    <div className='ms-3 d-flex flex-column'>
                          <span className='fs-6 fw-bold text-gray-900 me-1'>
                            Support Team
                          </span>
                      <span className='fs-8 mb-1 fw-bold'>{prettyDate(message?.created_at)}</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='me-3 d-flex flex-column align-items-end'>
                      <a href='#' className='fs-6 fw-bold text-gray-900 text-hover-primary ms-1'>
                        You
                      </a>
                      <div className='fs-8 mb-1 fw-bold'>{prettyDate(message?.created_at)}</div>
                    </div>
                    <div className='symbol symbol-35px symbol-circle '>
                      <img alt='Pic' src={currentAvatar}/>
                    </div>
                  </>
                )}
              </div>

              <div className={clsx('d-flex flex-row', isInMessage ? 'flex-row-reverse' : '')}>
                <span className={clsx('', messageId === String(index) ? 'd-block' : 'd-none')}>
                  {copyChatMessage({text: message.message, title: 'Copy', type: isChatForId})}
                </span>
                <div
                  className={clsx(
                    'px-5 py-3 keep-lines d-flex flex-column',
                    `bg-light-${state}`,
                    isInMessage ? 'rounded-end-5' : 'rounded-start-5',
                    'text-gray-900 fw-semibold mw-lg-400px',
                    `text-${isInMessage ? 'start' : 'end'}`,
                    (message.isUserEnd || Number(index) === messages.length - 1) ? 'rounded-bottom-5' : '',
                  )}
                  data-kt-element='message-text'
                >
                  <span
                    id={`${isChatForId}_message_${index + 1}`}
                    className={clsx(isInMessage ? 'text-end' : 'text-start')}
                  >
                    {message?.message}
                  </span>
                  <span className={clsx(
                    "text-muted fw-bold fs-7",
                    message?.isUserSame ? '' : 'd-none',
                    isInMessage ? 'text-end' : 'text-start'
                  )}>
                   {moment.tz(message?.created_at, 'UTC').format('HH:MM:ss')}
                  </span>
                </div>
              </div>
              {((Number(index) === Number(messages.length - 1)) && (Number(initialMessage?.status) === 4)) ?
                <div className='w-100 text-center text-gray-700 fs-5 fw-bold pt-5'>
                  Closed {prettyDate(message.created_at)}
                </div> : null
              }
            </div>
          </div>
        )
      }) : null}
      <div ref={messagesEndRef}/>
    </>
  )
}

export default ChatBodyScrollBar
