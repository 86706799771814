import React, {useContext, useEffect, useState} from 'react'
import {detailsDisclaimerText} from "@metronic/Components/Securities/Components.jsx"
import {
  cryptoIconsMapper,
  prettyDate,
  toTwoPrecision,
  TransactionBadgeIcon, TransactionStatusBadge
} from "@metronic/helpers/utils.jsx"
import {useTranslation} from "react-i18next"
import {TransactionsModalContext} from "@metronic/Components/Transactions/TransactionsDetailsModalsContext.jsx"
import {KTIcon} from "@metronic/_metronic/helpers/index"
import {Button, Col, Row} from "reactstrap"
import {clsx} from "clsx"
import {capitalize} from "lodash"
import {SecurityType} from "@metronic/Enums/Securities/SecurityType.js"

const TransactionsTab = ({consultEmail, selectedSecurities}) => {
  const [transactionsList, setTransactionsList] = useState([])
  const {t} = useTranslation()
  const {openTransferDetailsModal} = useContext(TransactionsModalContext)
  const [openDetailsMap, setOpenDetailsMap] = useState([])

  const openDetailsHandler = (e, id) => {
    e.preventDefault()

    setOpenDetailsMap(prevState =>
      prevState.map(item =>
        item.id === id
          ? {...item, isOpenDetails: !item.isOpenDetails}
          : item
      )
    )
  }

  useEffect(() => {
    if (selectedSecurities && selectedSecurities.transfers?.length) {
      setTransactionsList(selectedSecurities.transfers)
    }
  }, [selectedSecurities])

  useEffect(() => {
    const updatedDetailsMap = transactionsList.map(item => ({
      id: item.id,
      isOpenDetails: false
    }))

    setOpenDetailsMap(updatedDetailsMap)
  }, [transactionsList])

  return (
    <>
      <h4 className="text-gray-700 border-bottom-dashed border-1 border-gray-300 pb-1">Transactions details
        <span className="text-gray-800"> ({selectedSecurities?.security?.name})</span>
      </h4>
      <div className='table-responsive'>
        <table className='table align-middle table-row-bordered table-row-gray-400'>
          <thead className="border-bottom-0">
            <tr className='fw-bold fs-7 text-gray-700 bg-light text-nowrap px-1'>
              <th className='rounded-start py-2 fw-bold text-center px-1'>Transaction ID</th>
              <th className='py-2 fw-bold text-center px-1'>Quantity</th>
              <th className='fw-bold py-2 text-center px-1'>Type</th>
              <th className='fw-bold py-2 text-center px-1'>Purchased date</th>
              <th className='fw-bold py-2 text-center px-1'>Base Asset</th>
              <th className='fw-bold py-2 text-center px-1'>USD Amount</th>
              <th className='fw-bold py-2 text-center px-1'>Status</th>
              {selectedSecurities?.security?.type === SecurityType.BOND ?
                <th className='rounded-end fw-bold py-2 text-center px-1'>Details</th> : null
              }
            </tr>
          </thead>

          <tbody>
          {transactionsList ? (transactionsList?.length ?
              transactionsList.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr className={clsx('align-middle fs-7',
                      openDetailsMap.find(i => i.id === item.id)?.isOpenDetails && 'border-bottom-0',
                      Number(index) % 2 !== 0 ? 'bg-light-secondary' : ''
                    )}>
                      <td className="fw-bold text-primary p-1 text-center">
                        <a
                          id={`sec_detail_transaction_id_${index + 1}`}
                          className="cursor-pointer"
                          onClick={() => openTransferDetailsModal(item)}
                        >#{item.id}</a>
                      </td>
                      <td className="fw-bold p-1 text-center">
                        {item.meta['quantity']}
                      </td>
                      <td className="p-1 d-flex justify-content-center border-0">
                        <TransactionBadgeIcon
                          transactionId={item.id}
                          transactionType={item.type}
                          invoiceId={item.invoice_id}
                          invoiceAmount={item.usd_amount}
                        />
                      </td>
                      <td className="fw-bold p-1 text-center">{prettyDate(item.created_at)}</td>
                      <td className="p-1 d-flex justify-content-center border-0">
                        <div className="d-flex align-items-center">
                          <div className="h-20px w-20px rounded-circle me-2">
                            <img
                              src={cryptoIconsMapper[item.base_asset.currency.code]}
                              className="img-fluid"
                              alt="3"
                            />
                          </div>
                          <span className="fw-bold">{item.base_asset.currency.code}</span>
                        </div>
                      </td>
                      <td className="fw-bold p-1 text-center">${toTwoPrecision(Math.abs(Number(item.usd_amount)))}</td>
                      <td className="p-1 text-center">
                        <TransactionStatusBadge status={item.status} color={item.status_color}/>
                      </td>
                      {selectedSecurities?.security?.type === SecurityType.BOND ?
                        <td className="fw-bold p-1 text-center">
                          <Button
                            type="button"
                            className={clsx('btn btn-sm bg-transparent ps-1 p-0',
                              openDetailsMap.find(i => i.id === item.id)?.isOpenDetails ? 'active' : '')
                            }
                            onClick={(e) => openDetailsHandler(e, item.id)}
                          >
                            <KTIcon iconName="text-circle" className="fs-4"></KTIcon>
                          </Button>
                        </td> : null
                      }
                    </tr>

                    {(openDetailsMap.find(i => i.id === item.id)?.isOpenDetails &&
                      selectedSecurities?.security?.type === SecurityType.BOND) ?
                      <tr className={clsx('text-center', Number(index) % 2 !== 0 ? 'bg-light-secondary' : '')}>
                        <td colSpan={8} className="pt-0 align-middle">
                          <Row className="d-flex align-items-center pt-0 ms-5 justify-content-center"
                               style={{maxWidth: '95%'}}>
                            {Object.keys(item.meta).length ? Object.entries(item.meta).map(([key, value], idx) => {

                              return (
                              key !== 'quantity' ?
                                <Col
                                  className="col-6 text-nowrap d-flex justify-content-between border-bottom-dashed border-1 border-gray-300"
                                  key={idx}>
                                  <span className="fw-bold">{capitalize(key)}:</span>
                                  <span
                                    className="fw-bold text-gray-600">
                                    {key === 'coupon_rate' ? `${value}%` : value}
                                  </span>
                                </Col> : null
                              )
                            }) :
                              <span className="w-100 text-center fw-bold">No data</span>
                            }
                          </Row>
                        </td>
                      </tr> : null
                    }
                  </React.Fragment>
                )
              }) : (<tr>
                <td colSpan={8} className='text-center'>{t("No data")}</td>
              </tr>))
            : (<tr>
              <td colSpan={8} className='text-center'>{t("Loading")}...</td>
            </tr>)
          }
          </tbody>

        </table>
      </div>

      {detailsDisclaimerText(consultEmail)}
    </>
  )
}

export default TransactionsTab
