import clsx from 'clsx'
import React, {useState} from 'react'
import {KTIcon} from '../../../helpers'
import {CreateAppModal} from '../../../partials'
import {useLayout} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'
import {Link} from "@inertiajs/react";

const Toolbar1Default = ({isWallet, activeTab}) => {
  const {classes} = useLayout()
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const VITE_SHOW_SECURITIES = import.meta.env.VITE_SHOW_SECURITIES

  return (
    <>
      <div className='toolbar py-5 py-lg-15' id='kt_toolbar'>
        {/* begin::Container */}
        <div
          id='kt_toolbar_container'
          className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack d-print-none')}
        >
          <DefaultTitle />

          <div className='d-flex align-items-center'>
            {/*<div>*/}
            {/*  <a*/}
            {/*    href='#'*/}
            {/*    className='btn btn-custom py-2 px-3 btn-active-white btn-flex btn-color-white btn-active-color-white'*/}
            {/*    data-kt-menu-trigger='click'*/}
            {/*    data-kt-menu-placement='bottom-end'*/}
            {/*    data-kt-menu-flip='top-end'*/}
            {/*  >*/}
            {/*    <KTIcon iconName='questionnaire-tablet' className='fs-5 me-1' />*/}
            {/*    Marks all as read*/}
            {/*  </a>*/}
            {/*</div>*/}


            {/* begin::Button */}

            {(isWallet && VITE_SHOW_SECURITIES === 'true') ?
              <>
                <Link
                  id="wallet_investments_button"
                  href='/wallet/investments'
                  className={`btn btn-${activeTab === 'investments' ? 'light text-primary' : 'custom'} btn-active-white py-1 me-3
                  btn-color-${activeTab === 'investments' ? 'dark' : 'light'} btn-active-color-${activeTab === 'investments' ? 'primary' : 'light'}`
                  }
                >
                  Investment details
                </Link>

                <Link
                  id="wallet_balance_button"
                  href='/wallet'
                  className={`btn btn-${activeTab === 'balance' ? 'light text-primary' : 'custom'} btn-active-white py-1
                  btn-color-${activeTab === 'balance' ? 'dark' : 'light'} btn-active-color-${activeTab === 'balance' ? 'primary' : 'light'}`
                  }
                >
                  My Balance
                </Link>
              </> : null
            }


            {/* end::Button*/}
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Container */}
      </div>
      <CreateAppModal show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)}/>
    </>
  )
}

export {Toolbar1Default}
